import request from '@/utils/http'

/**
 * 支付方式列表
 * @param payload
 */
export function payment_mode_list(payload) {
    return request({ url: '/Payment/lists', method: 'post', data: payload })
}

/**
 * 保存配置信息
 * @param payload
 */
export function save_setting(payload) {
    return request({ url: '/Payment/saveSetting', method: 'post', data: payload })
}

/**
 * 获取支付方式配置信息
 * @param payload
 */
export function get_setting(payload) {
    return request({ url: '/Payment/getSetting', method: 'post', data: payload })
}